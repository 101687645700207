.welcome {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  justify-content: center;
  align-items: stretch;
  
  .top {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .middle {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding-bottom: 10%;

     p:first-of-type {
       text-align: center;
       margin-bottom: 3rem;
      }
      
      a {
       margin-bottom: 2rem;
     }
  }
}
