.home {
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 80%;
   justify-content: center;
   align-items: stretch;
   // max-width: 768px;
   
   .middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 20%;

      & img:last-of-type {
         padding-top: 3rem;
      }
   }
}
