.about {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   img:first-of-type {
      margin-bottom: 8rem;
   }
   
   p {
      margin-bottom: 0.5rem;
   }

   p:last-of-type {
      margin-top: 1rem;
   }

   button:last-of-type {
      font-size: 200%;
      margin-top: 4rem;
   }
}
