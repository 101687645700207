:root {
  // --clr-green: #bae11a;
  --clr-green: #81c341;
  // --clr-green-ok: #9fff00;
  --clr-red: #ff6f6f;
  --clr-text: white;
  --clr-background: black;
  --clr-darkgrey: hsl(0, 0%, 20%);
  --clr-midgrey: hsl(0, 0%, 50%);
  --clr-lightgrey: hsl(0, 0%, 80%);
  --size-introtxt: 1.35rem;
  --size-introtxt-lineheight: 1.75rem;
  --size-standard: 1.35rem;
  --size-big: 3rem;
  --pad-standard: 1rem;
  --debug:1;
  font-size: 12px;
  background-color: black;
  background-image: url("img/image-fond.png");
  background-size: cover;
  // overflow: hidden;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) /*and (orientation: landscape) */ {
    font-size: 18px;
  }
  @media (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
    font-size: 10px;
  }
}

*,
*::before,
*::after {
  // border: 1px solid red; /* Debug */
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

// https://usefulangle.com/post/278/html-disable-pull-to-refresh-with-css
body {
  overscroll-behavior: contain; // Empecher pull-to-refresh
}

.App {
  display: flex;
  flex-direction: column;
  overflow: scroll; // Pour la table du module Search
  height: 100vh;
  font-size: var(--size-introtxt);
  line-height: var(--size-introtxt-lineheight);
  justify-content: center;
  align-items: center;
  // max-width: 768px;
  // width:100vw;

  color: var(--clr-text);
  // color: black;

  // Pour afficher que le device doit être retourné en vertical.
  // A posé pb avec certains devices quand le clavier est affiché, la webview diminuant
  // ça laisse l'appli "penser" que le device est en paysage ! (le "bug" remonté par Nicolas)
  // @media (min-device-width: 320px) and (max-device-width: 800px) and (orientation: landscape) {
  //   > div {
  //     --debug:2;
  //     display: none;
  //   }
  //   > p:first-of-type {
  //     --debug:3;
  //     display: block;
  //     text-align: center;
  //     margin: 0 10vw;
  //     font-size: 2rem;
  //     line-height: 3rem;
  //     color: var(--clr-red);
  //   }
  // }
  // Du coup, on masque le message pour le moment
  p:first-of-type {
    display: none;
  }

}

.warn-landscape {
  display: none;
  @media (min-device-width: 320px) and (max-device-width: 800px) and (orientation: landscape) {
    display: block;
  }
}

.top {
  // background-color: rgba(255,0,0,.25);
  flex: 0 0 20vh; /* grow shrink basis 20% de la hauteur du viewport */
  min-height: 100px;
}

.middle {
  // background-color: rgba(0,255,0,.25);
  flex-shrink: 1;
  flex-grow: 1;
}

.bottom {
  // background-color: rgba(0,0,255,.25);
  flex: 0 0 7rem;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 2rem;
}

.fa-toggle-on,
.fa-toggle-off,
.fa-toggle-unset {
  font-size: 2rem;
  margin: 1rem;
}

.fa-toggle-on {
  color: var(--clr-green);
}

.fa-toggle-off {
  color: var(--clr-red);
}

.fa-toggle-unset {
  color: var(--clr-midgrey);
}

.top-right-icon {
  position: absolute;
  right: 3rem;
  top: 2rem;
}

.top-left-icon {
  position: absolute;
  left: 3rem;
  top: 2rem;
}

.error {
  color: var(--clr-red);
  font-size: 2rem;
  line-height: 3rem;
  overflow-wrap: anywhere;
}

a {
  color: var(--clr-green);
  text-decoration: none;
}
