.admin {
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 80%;
   justify-content: center;
   align-items: stretch;

   .top {
      line-height: 1.15em;
   }
   
   .middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: flex-start;
      // padding-bottom: 20%;
      // gap: 15px;

      input {
         margin-bottom: 1rem;
      }

      .error {
         text-align: center;
         margin-bottom: 2rem;
      }

      .zone-info {
         display: flex;
         width: 100%;
         // height: 4rem;
         justify-content: space-between;
         border-top: 1px solid var(--clr-midgrey);
         padding: 1rem 0;

         div {
            p:nth-of-type(1) {
               font-size: 1.25rem;
               line-height: 1.75rem;
            }
            p:nth-of-type(2) {
               font-size: 3rem;
               line-height: 3.5rem;
            }
         }

      }
   }
}
