input {
  border: 1px solid var(--clr-green);
  padding: var(--pad-standard);
  // margin: 0.75rem;

  -webkit-appearance: none;   // Fix bords arrondis des champs "search" sur Safari iOS
  -webkit-border-radius: 0;

  border-radius: 12px;
  font-size: var(--size-standard);
  width: 100%;
  border-width: 2px;
  background-color: rgba($color: #ffffff, $alpha: 0.2);
  color: var(--clr-text);

  &:focus {
    box-shadow: 0 0 0 3px rgba(#84bd00, 0.6);
    outline: none;
  }

  &::placeholder {
    color: var(--clr-midgrey);
  }
}
