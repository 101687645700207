.bottom-sig {
  display: flex;
//   flex: 0 0 5rem;
  display: flex;
  justify-content: space-between;
  color: var(--clr-green);
//   opacity: 75%;
  font-size: 80%;
  padding-bottom: 3rem;
}
