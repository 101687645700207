.init {
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 80%;
   justify-content: center;
   align-items: stretch;
   
   .middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 20%;
      // gap: 15px;

      p:first-of-type {
         text-align: center;
         margin-bottom: 2rem;
      }

      input {
         margin-bottom: 1rem;
      }
   }
}