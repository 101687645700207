.scanner-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.scanner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  justify-content: center;
  align-items: stretch;

  @media (orientation: landscape) {
    width: 50%;
  }

  .bottom {
    @media (orientation: landscape) {
      flex: 0 0 5rem;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding-bottom: 20%;

    & > p:first-of-type {
      font-size: 2rem;
    }

    .big-check {
      font-size: 15rem;
      color: rgba($color: #ffffff, $alpha: .5);
    }
    
  }
}

.red {
  background-color: var(--clr-red);
}

.green {
  background-color: var(--clr-green);
}

.red,
.green {
  & #code {
    color: black;
  }

  & .buttonlink {
    color: black;
  }
}

#code {
  color: white;
  margin-top: 5rem;
  font-size: 3rem;
  line-height: 4rem;
  overflow-wrap: anywhere;
}
