.search {
  --pad-top: 12rem;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  justify-content: flex-start;
  align-items: stretch;

  .top {
    position: fixed;
    height: var(--pad-top);
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    //  background-image: url("../img/image-fond.png");
    //  background-size: cover;

    //  & > div {
    //    margin-left: 10vw;
    //    margin-right: 10vw;
    //  }

    & > p:first-of-type {
      margin-top: 2rem;
      font-size: 150%;
    }

    & input[type="search"] {
      width: 80%;
      margin-top: 1rem;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & > * {
      width: 100%;
    }

    & table {
      border-collapse: collapse;
      margin-top: calc(var(--pad-top) + 1rem);
      // margin-top:50rem;
    }

    & th {
      text-align: left;
      font-weight: 100;
      color: var(--clr-lightgrey);
    }

    & thead {
      border-bottom: 1px solid var(--clr-lightgrey);
    }

    & tbody tr > td {
      // padding: 0.65rem 0;  // Pas besoin grace a margin des fa-toggle-xxx
      border-bottom: 1px solid var(--clr-midgrey);
    }

    & tbody::before {
      // https://stackoverflow.com/questions/20917222/padding-on-tbody
      content: "";
      display: block;
      height: 8px;
    }

    p:last-of-type {
      margin-top: 1rem;
      margin-bottom: 2rem;
      color: var(--clr-red);
    }
  }
}
