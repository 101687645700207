.detail {
  // display: flex;
  // flex-direction: column;
  // height: 100%;

  & > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 1rem;

    & > button {
       margin-left: 3rem;
    }
  }

  // &:focus {
  // outline-color: var(--clr-green);
  // outline: none;
  // border-width: 3px;
  // }
}

.detail-content {
   background-color: rgba(255,255,255,.85);
   color: black;
   border-radius: 8px;
   padding: 1rem;
   position: absolute;
   top: 6rem;
   bottom: 5rem;
   left: 5rem;
   right: 5rem;
 }